import React, { useState } from 'react';
import FormControl from 'src/components/base/formControl';
import FileInput from 'src/components/base/fileInput';
import { submitForm } from 'src/util';
import Dots from 'react-activity/dist/Dots';
import 'react-html5-camera-photo/build/css/index.css';
import 'react-activity/dist/Dots.css';
import * as classes from './blockUploadForm.module.scss';

const TYPES = {
  healthInsurance: {
    label: 'Health Insurance Card',
    help: 'Upload images of both the front and back of your health insurance card. Make sure that all text and numbers on your card are readable in the images.',
  },
  id: {
    label: 'ID Card',
    help: 'Boulder accepts state or national government-issued photo ID that includes your birthdate, such as a driver’s license or state ID card. Upload images of both the front and back of your card. Make sure that all text and numbers on your card are readable in the images.',
  },
  other: {
    label: 'Other',
  },
};

const BlockUploadForm = ({ onComplete }) => {
  const [submitting, setSubmitting] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [dob, setDob] = useState();
  const [type, setType] = useState();
  const [other, setOther] = useState();
  const [files, setFiles] = useState([]);

  return (
    <div>
      <form
        id="refer"
        action={process.env.GATSBY_FORM_HANDLER}
        onSubmit={(evt) => {
          setSubmitting(true);
          submitForm(evt, files, onComplete, () => setSubmitting(false));
        }}
      >
        <input type="hidden" name="id" value="onboarding" />
        <input type="hidden" name="requestType" value="Upload" />

        <p>You can use this form to share images or other files with Boulder. </p>

        <FormControl
          name="firstName"
          label="First name"
          value={firstName}
          setValue={setFirstName}
          required
        />
        <FormControl
          name="lastName"
          label="Last name"
          value={lastName}
          setValue={setLastName}
          required
        />
        <FormControl
          name="DOB"
          label="Date of Birth"
          value={dob}
          setValue={setDob}
          required
          type="date"
        />
        <FormControl
          name="uploadType"
          label="I want to upload a..."
          options={Object.entries(TYPES)}
          valueFn={(s) => s[0]}
          labelFn={(s) => s[1].label}
          value={type}
          setValue={setType}
          required
        />

        {type && TYPES[type].help && <p className={classes.help}>{TYPES[type].help}</p>}

        <FileInput files={files} setFiles={setFiles} />

        <FormControl
          name="additional"
          label="Anything else you'd like us to know?"
          value={other}
          setValue={setOther}
          multi
        />

        <div className={classes.cta}>
          <button type="submit" className="button">
            {submitting ? <Dots color="white" /> : <>UPLOAD</>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BlockUploadForm;
